


















































































import { Component, Vue } from 'vue-property-decorator'
import WidgetTitle from '@/components/common/WidgetTitle.vue'
import LoadStatusMessage from '@/components/common/LoadStatusMessage.vue'
import BezeButton from '@/components/common/BezeButton.vue'
import SingleCakeFillings from '@/components/cakes/single/Fillings.vue'
import { CakeFilling } from '@/shared/models/fillings'
import { ProductListItem } from '@/shared/models/cakes'
import { CARD_ID } from '@/shared/const'
import CartTotal from '@/components/cart/CartTotal.vue'

@Component({
  components: {
    WidgetTitle,
    LoadStatusMessage,
    BezeButton,
    SingleCakeFillings,
    CartTotal
  }
})
export default class Cart extends Vue {
  isFillingModalVisible = false
  currentCakeIdToFillingAdds: ProductListItem | null = null // торт к которому добавляем начинку
  statusMsgConfig = {
    type: 'empty',
    title: 'Корзина пуста',
    subtitle: 'Перейдите в каталог, чтобы начать покупки',
    btnText: 'Перейти в каталог',
    btnLink: '/catalog'
  }

  data(): Record<string, unknown> {
    return {
      CARD_ID
    }
  }

  get cakes(): Array<ProductListItem> {
    return this.$store.getters.cart
  }

  mounted(): void {
    if (!this.$store.getters.deliveryMethods.length) {
      this.$store.dispatch('getDeliveryMethods')
    }
  }

  pickFilling(cake: ProductListItem): void {
    this.currentCakeIdToFillingAdds = { ...cake }
    this.isFillingModalVisible = true
  }

  saveFilling(filling: CakeFilling): void {
    if (!filling) {
      this.isFillingModalVisible = false
      return
    }

    if (!this.currentCakeIdToFillingAdds) return

    if (!this.currentCakeIdToFillingAdds.filling) {
      this.currentCakeIdToFillingAdds.filling = {
        id: 0,
        name: ''
      }
    }

    this.currentCakeIdToFillingAdds.filling.id = filling.id
    this.currentCakeIdToFillingAdds.filling.name = filling.title.rendered

    this.$store.dispatch('addToCart', { ...this.currentCakeIdToFillingAdds })
    this.isFillingModalVisible = false
  }
}
